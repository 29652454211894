import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import { Container } from 'react-bootstrap';

import { generateLink } from 'dw-contentful-components/src/util/generateLink';
import { slugifyString } from '../../util/slugifyString';

import '../../styles/CategoryLinks.scss';

const props = {
  categories: PropTypes.arrayOf(PropTypes.object).isRequired,
  categoryTitle: PropTypes.string,
};

const defaultProps = {
  categoryTitle: '',
};

const CategoryLinks = ({ categories, categoryTitle }) => {
  const [filteredCategories, setFilteredCategories] = useState([]);

  useEffect(() => {
    categories.unshift('All Articles');

    const temp = [];
    categories.forEach((name) => {
      const slugified = slugifyString(name);
      const isAll = slugified === 'all-articles';

      temp.push({
        name,
        slugified,
        url: generateLink({
          jspFileName: 'list',
          queryKey: isAll ? '' : 'category',
          queryValue: isAll ? '' : slugifyString(name),
        }),
      });
    });

    setFilteredCategories(temp);
  }, []);

  return (
    <Container className="category-links">
      <ol className="category-links__list">
        {filteredCategories.map(({ name, slugified, url }) => {
          const anchorClasses = classnames('category-links__link', {
            'category-links__link--current': name === categoryTitle,
          });

          return (
            <li className="category-links__item" key={slugified}>
              <a
                href={url}
                className={anchorClasses}
                data-testid="category-link"
              >
                {name}
              </a>
            </li>
          );
        })}
      </ol>
    </Container>
  );
};

CategoryLinks.propTypes = props;
CategoryLinks.defaultProps = defaultProps;

export { CategoryLinks };
