import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';

import { CategoryLinks } from '../components/CategoryLinks';
import { HeroBannerWrapper } from '../components/HeroBannerWrapper';
import { Section } from '../components/Section';

import standardiseContentfulId from '../util/standardiseContentfulId';
import getGlobalCssForBrand from '../util/getGlobalCssForBrand';
import '../styles/index.scss';
import { getHomeSchemaMarkup } from '../util/schemaMarkup';

const props = {
  pageContext: PropTypes.object,
};

const defaultProps = {
  pageContext: {
    brand: 'law',
    pageData: {
      sections: [],
    },
  },
};

const referenceCardSectionType = "ContentfulReferenceCardSection";
const articleComponentType = "ContentfulArticlePage";
const buttonWithContentType = "ContentfulButtonWithContent";
// Sort by date
// Descending order
const getSortedPostedOn = (a, b) => new Date(b.postedOn).getTime() - new Date(a.postedOn).getTime();
const MAX_ARTICLES_LIST = 15;
// Filter latest articles
// And show maximum MAX_ARTICLES_LIST articles
const filterSectionArticle = (sections) => {
  const updateSec = sections.map(i => {
    if(i.__typename !== referenceCardSectionType) {
      return i;
    }

    const buttonSection = i.referenceCards.find(ref => ref.__typename === buttonWithContentType);
    let articlesOnly = i.referenceCards.filter(ref => ref.__typename === articleComponentType);
    articlesOnly = articlesOnly.sort(getSortedPostedOn).splice(0, MAX_ARTICLES_LIST);
    return {
      ...i,
      referenceCards: [...articlesOnly, buttonSection].filter(c => c)
    }
  })

  return updateSec;
}

/**
 * Page is used for the Content Hub homepage and list page
 * @param {object} pageContext Contains details of the page like the brand and data from contentful
 * @returns
 */
const Page = ({ pageContext, location }) => {
  const {
    brand,
    pageData: { heroBanner, sections: sectionsAll },
    categories,
    seoMeta,
  } = pageContext;

  useEffect(() => {
    removeEmptyDescription();
  }, []);

  const removeEmptyDescription = () => {
    if (typeof window !== "undefined") {
      document.head.querySelectorAll("meta[name='description']").forEach(meta => {
        if (!meta.getAttribute("content")) {
          meta.remove();
        }
      });
      document.body.querySelectorAll("meta[name='description']").forEach(meta => {
        if (!meta.getAttribute("content") || meta.getAttribute("data-react-helmet") == "true") {
          meta.remove();
        }
      });
    }
  }

  const sections = filterSectionArticle(sectionsAll);

  const schemaMarkup = getHomeSchemaMarkup({
    location,
    heroBanner,
    sections,
    brand
  });

  return (
    <div className={`cms ${brand}`} id={standardiseContentfulId('top')}>
      <Helmet>
        {process.env.NODE_ENV === 'development' && (
          <link rel="stylesheet" href={getGlobalCssForBrand(brand)} />
        )}
        <link
          rel="stylesheet"
          href="//maxcdn.bootstrapcdn.com/font-awesome/4.3.0/css/font-awesome.min.css"
        />
        <title>{seoMeta?.seoTitle}</title>
        <meta name="description" content={seoMeta?.seoDescription} />
        {
          Object.keys(schemaMarkup).length > 0 &&
          <script type="application/ld+json">{JSON.stringify(schemaMarkup)}</script>
        }
      </Helmet>
      <HeroBannerWrapper brand={brand} heroBanner={heroBanner} variant={1} />
      <CategoryLinks categories={categories} />
      {sections.map((section) => (
        <Section key={section.id} brand={brand} data={section} />
      ))}
    </div>
  );
};

Page.propTypes = props;
Page.defaultProps = defaultProps;

export default Page;
